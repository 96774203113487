// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-automation-js": () => import("/opt/build/repo/src/pages/automation.js" /* webpackChunkName: "component---src-pages-automation-js" */),
  "component---src-pages-backup-security-js": () => import("/opt/build/repo/src/pages/backup-security.js" /* webpackChunkName: "component---src-pages-backup-security-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-desktops-js": () => import("/opt/build/repo/src/pages/desktops.js" /* webpackChunkName: "component---src-pages-desktops-js" */),
  "component---src-pages-documentation-js": () => import("/opt/build/repo/src/pages/documentation.js" /* webpackChunkName: "component---src-pages-documentation-js" */),
  "component---src-pages-hardware-js": () => import("/opt/build/repo/src/pages/hardware.js" /* webpackChunkName: "component---src-pages-hardware-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("/opt/build/repo/src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-infrastructure-js": () => import("/opt/build/repo/src/pages/infrastructure.js" /* webpackChunkName: "component---src-pages-infrastructure-js" */),
  "component---src-pages-itconsulting-js": () => import("/opt/build/repo/src/pages/itconsulting.js" /* webpackChunkName: "component---src-pages-itconsulting-js" */),
  "component---src-pages-managed-services-js": () => import("/opt/build/repo/src/pages/managed-services.js" /* webpackChunkName: "component---src-pages-managed-services-js" */),
  "component---src-pages-message-sent-js": () => import("/opt/build/repo/src/pages/message-sent.js" /* webpackChunkName: "component---src-pages-message-sent-js" */),
  "component---src-pages-security-js": () => import("/opt/build/repo/src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-support-js": () => import("/opt/build/repo/src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

